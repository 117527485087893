.a {
    
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* No repeat of the image */
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    text-align: center; /* Center align the text */
    padding: 20px; /* Add some padding */
  }
  
  .a h1 {
    color: #fff; /* White color for the text */
    font-size: 2.5rem; /* Adjust font size */
    font-weight: bold; /* Make the text bold */
    text-shadow: 3px 3px 6px rgba(255, 255, 255, 0.7); /* Add shadow for better readability */
    margin: 0; /* Remove default margin */
    line-height: 1.2; /* Adjust line height for better readability */
    letter-spacing: 1px; /* Slightly increase letter spacing */
  }








  .cou {
    padding: 20px; /* Add padding around the content */
    background-color: #f8f9fa; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    max-width: 800px; /* Limit the width for better readability */
    margin: 0 auto; /* Center the div horizontally */
  }
  
  .cou ul {
    list-style-type: none; /* Remove default list bullets */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
  
  .cou li {
    background: #ffffff; /* White background for list items */
    border: 1px solid #ddd; /* Light border for separation */
    border-radius: 4px; /* Rounded corners for list items */
    padding: 15px; /* Padding inside each list item */
    margin-bottom: 10px; /* Space between list items */
    font-size: 1rem; /* Font size for readability */
    line-height: 1.6; /* Line height for better readability */
  }
  
  .cou li::before {
    content: "✔️ "; /* Add a checkmark before each list item */
    color: #28a745; /* Green color for the checkmark */
    font-size: 1.2rem; /* Slightly larger checkmark */
  }
  
  .cou li:nth-child(odd) {
    background-color: #f1f1f1; /* Alternating background color for odd items */
  }
  
  .cou li:hover {
    background-color: #e9ecef; /* Highlight background on hover */
    cursor: pointer; /* Pointer cursor on hover */
  }
  .cou a{
    text-decoration: none;
  }
  