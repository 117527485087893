/* Modal Backdrop */
.modal-backdrop {
    opacity: 0.5 !important; /* Ensure backdrop is slightly transparent */
    z-index: 1040 !important; /* Lower z-index than modal */
}

/* Modal Dialog */
.modal-dialog {
    width: 80vw; /* Adjust width for larger screens */
    max-width: 600px; /* Maximum width for responsiveness */
    height: auto; /* Allow height to adjust based on content */
    max-height: 80vh; /* Maximum height to ensure responsiveness */
    margin: auto; /* Center horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: scaleUp 0.3s ease-out; /* Animation for scaling up */
    z-index: 1050; /* Ensure modal is in foreground */
    position: fixed; /* Ensures it stays centered */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for exact centering */
    padding: 0 10px; /* Small horizontal padding */
    box-sizing: border-box;
}

/* Modal Content */
.modal-content {
    border: none; /* Remove default border */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Hide overflow content */
    background-color: #fff; /* White background */
    position: relative; /* Ensure z-index applies to content */
    width: 100%;
}

/* Modal Header */
.modal-header {
    border: none; /* Remove border */
    padding: 10px; /* Add padding */
    position: absolute; /* Position absolutely for close button */
    top: 10px; /* Adjust top position */
    right: 10px; /* Adjust right position */
    z-index: 1060; /* Ensure header is above content */
}

/* Close Button */
.modal-header .close {
    font-size: 1.8rem; /* Increase size for better visibility */
    color: black; /* Dark color for close button */
    opacity: 1;
    background-color: transparent;
    border: none; /* Remove any border */
    cursor: pointer; /* Change cursor to pointer */
    line-height: 1;
}

/* Modal Body Custom */
.modal-body-custom {
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: center; /* Center items vertically */
    padding: 20px; /* Add padding */
}

/* Image Styling */
.modal-image {
    width: 40%; /* Adjust width of the image */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the area while maintaining aspect ratio */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for image */
}

/* Text Container */
.modal-text {
    width: 60%; /* Adjust width of the text container */
    padding: 20px;
    color: #333; /* Dark text color for readability */
    text-align: left; /* Align text to the left */
}

/* Heading Styles */
.modal-text h2 {
    font-size: 1.5rem; /* Adjust font size for readability */
    margin-bottom: 0.5rem;
    color: #333; /* Dark color */
}

.modal-text h3 {
    font-size: 2rem; /* Adjust font size for readability */
    margin-bottom: 0.5rem;
    color: black; /* Dark color */
}

/* Modal Footer */
.modal-footer {
    border-top: none; /* Remove top border */
    background-color: #f8f9fa; /* Light background color */
    padding: 15px 20px; /* Padding for spacing */
    display: flex;
    justify-content: center; /* Center the button */
    flex-direction: column; /* Stack button vertically */
    width: 100%; /* Full width for footer */
    box-sizing: border-box; /* Include padding in width calculation */
    margin-top: 10px; /* Space above footer */
}

/* Button Styling */
.btn-outline-light.btn-dark {
    border-color: #f4476b;
    color: #f4476b;
    background: transparent;
    padding: 12px 20px;
    border-radius: 25px;
    font-size: 1rem;
    transition: background 0.3s, color 0.3s;
    text-align: center;
}

.btn-outline-light.btn-dark:hover, .btn-outline-light.btn-dark:focus {
    background: #f4476b;
    color: #fff;
}

/* Animation for modal scaling up */
@keyframes scaleUp {
    from {
        transform: scale(0.8); /* Slightly adjusted start scale */
    }
    to {
        transform: scale(1);
    }
}

/* Responsive Adjustments */

/* Small screens (phones, small tablets) */
@media (max-width: 768px) {
    .modal-dialog {
        width: 90vw; /* Increase width for smaller screens */
        max-width: 90vw; /* Ensure it doesn't overflow */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        transform: translate(-50%, -50%); /* Adjust for exact centering */
        padding: 0 5px; /* Reduce horizontal padding */
    }

    .modal-body-custom {
        flex-direction: column; /* Stack image and text vertically */
        padding: 15px; /* Reduce padding */
    }

    .modal-image {
        width: 100%; /* Full width for smaller screens */
        max-height: 200px; /* Limit height */
        margin-bottom: 15px; /* Space below image */
    }
    
    .modal-text {
        width: 100%; /* Full width for text container */
        padding: 0; /* Remove padding */
        text-align: center; /* Center text */
    }

    .modal-text h2, .modal-text h3 {
        font-size: 1.2rem; /* Reduce font size */
        margin-bottom: 0.5rem;
    }

    .modal-footer {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .btn-outline-light.btn-dark {
        padding: 10px 15px; /* Adjust padding for better touch targets */
        font-size: 0.9rem; /* Slightly smaller font size */
    }

    /* Close Button Adjustments */
    .modal-header {
        top: 5px; /* Adjust position */
        right: 5px;
    }

    .modal-header .close {
        font-size: 1.5rem; /* Adjust size for small screens */
    }
}

/* Extra small screens (very small phones) */
@media (max-width: 480px) {
    .modal-dialog {
        width: 95vw; /* Further increase width for very small screens */
        max-width: 95vw; /* Ensure it doesn't overflow */
    }

    .modal-image {
        max-height: 150px; /* Further limit height */
    }

    .modal-text h2, .modal-text h3 {
        font-size: 1rem; /* Further reduce font size */
    }

    .btn-outline-light.btn-dark {
        padding: 8px 12px; /* Further adjust padding */
        font-size: 0.8rem; /* Reduce font size */
    }

    .modal-header .close {
        font-size: 1.3rem; /* Adjust size for very small screens */
    }
}
