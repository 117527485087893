/* General Styles for .SecondContent */
.SecondContent {
  margin: 5% auto 0 auto; /* Center content horizontally */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  padding: 1rem; /* Add padding for better spacing */
  color: #fff; /* Text color for better readability */
  background-color: #222; /* Background color for contrast */
  width: 95%; /* Ensure content fits within the screen */
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .SecondContent {
    margin-top: 4%; /* Adjust margin for medium screens */
    padding: 0.75rem; /* Adjust padding */
    font-size: 0.95rem; /* Slightly reduce font size */
  }
}

@media (max-width: 768px) {
  .SecondContent {
    margin-top: 3%; /* Reduce margin for smaller screens */
    padding: 0.5rem; /* Reduce padding */
    font-size: 0.9rem; /* Adjust font size for better readability */
    width: 90%; /* Ensure content fits within smaller screens */
  }
}

@media (max-width: 480px) {
  .SecondContent {
    margin-top: 2%; /* Further reduce margin for very small screens */
    padding: 0.25rem; /* Further reduce padding */
    font-size: 0.8rem; /* Further reduce font size */
    width: 100%; /* Ensure content fits within the screen */
  }
}
