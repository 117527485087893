/* Container Styles */
.Events {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Create a responsive grid layout */
  gap: 1rem; /* Space between the cards */
  padding: 1rem;
  background-color: #000;
}

/* Card Styles */
.card {
  background-color: #222;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box; /* Ensure padding is included in the width */
  display: flex;
  flex-direction: column;
  height: 100%; /* Make all cards equal in height */
}

.card:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Add a shadow for emphasis */
}

/* Card Image Styles */
.card-img-top {
  width: 100%;
  height: 180px; /* Consistent height */
  object-fit: cover;
}

/* Card Body Styles */
.card-body {
  padding: 1rem;
  text-align: center;
  flex-grow: 1; /* Allows the body to expand to fill available space */
}

/* Card Title Styles */
.card-title {
  color: #ff7e5f;
  font-size: 1.2rem; /* Adjusted font size */
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 0.5rem 0;
}

/* List Group Styles */
.list-group {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-group-item {
  background-color: #333;
  color: #fff;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 0.9rem; /* Adjusted font size */
  padding: 0.5rem; /* Increased padding for better readability */
  border-top: 1px solid #444; /* Added border for separation */
}

.list-group-item b {
  color: #ff7e5f;
}

.list-group-item:first-child {
  border-top: none; /* Remove border for the first item */
}

.list-group-item:hover {
  background-color: #444;
}

/* Button Styles */
button {
  display: block;
  width: 100%;
  background-color: #ff7e5f;
  border: none;
  padding: 0.75rem; /* Adjusted padding */
  text-align: center;
  font-size: 1rem; /* Adjusted font size */
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 0.5rem; /* Adjusted margin */
}

button:hover {
  background-color: #feb47b;
  transform: scale(1.05);
}

/* Link Styles */
.custom-link {
  text-decoration: none;
  color: white;
}

.custom-link:hover {
  text-decoration: none;
}

/* Responsive Styles */

/* Tablets and larger phones (portrait mode) */
@media (max-width: 1024px) {
  .card-title {
    font-size: 1.1rem;
  }

  .list-group-item {
    font-size: 0.85rem;
  }

  button {
    font-size: 0.95rem;
  }

  .Events {
    padding: 1rem;
  }
}

/* Phones (landscape mode) */
@media (max-width: 768px) {
  .card-title {
    font-size: 1rem;
  }

  .list-group-item {
    font-size: 0.8rem;
  }

  button {
    font-size: 0.9rem;
  }

  .Events {
    padding: 0.75rem;
  }
}

/* Phones (portrait mode) */
@media (max-width: 480px) {
  .card-title {
    font-size: 0.9rem; /* Smaller title for very small screens */
  }

  .list-group-item {
    font-size: 0.75rem; /* Adjusted font size */
  }

  button {
    font-size: 0.85rem;
    padding: 0.5rem; /* Adjusted padding for smaller screens */
  }

  .Events {
    padding: 0.5rem;
  }
}
