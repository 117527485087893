/* FourthContent.css */

/* Base Styles */
.FourthContent {
    background-color: #f4f4f4; /* Light background for contrast */
    padding: 2rem; /* Padding around the content */
    text-align: center; /* Center-align text */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin: 2rem auto; /* Margin for spacing and centering */
    max-width: 90%; /* Ensure content doesn't stretch too wide */
    box-sizing: border-box; /* Include padding in width */
  }
  
  /* Heading Styles */
  .FourthContent h1 {
    color: #333; /* Dark text color for readability */
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 2rem; /* Default font size */
    margin-bottom: 1rem; /* Space below heading */
    line-height: 1.4; /* Improved line spacing */
  }
  
  /* Paragraph Styles */
  .FourthContent p {
    color: #555; /* Slightly lighter text color */
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 1rem; /* Default font size */
    line-height: 1.6; /* Improved line spacing for readability */
    margin: 0 auto; /* Center-align with automatic margins */
    padding: 0 1rem; /* Padding on left and right */
    max-width: 800px; /* Max width to prevent text from stretching too wide */
  }
  
  /* Responsive Styles */
  
  /* Tablets and larger phones */
  @media (max-width: 1024px) {
    .FourthContent {
      padding: 1.5rem; /* Adjust padding */
    }
    
    .FourthContent h1 {
      font-size: 1.75rem; /* Adjust font size for tablets */
    }
  
    .FourthContent p {
      font-size: 0.95rem; /* Adjust font size for tablets */
      padding: 0 0.75rem; /* Adjust padding */
    }
  }
  
  /* Phones (landscape mode) */
  @media (max-width: 768px) {
    .FourthContent {
      padding: 1rem; /* Adjust padding */
    }
    
    .FourthContent h1 {
      font-size: 1.5rem; /* Adjust font size for phones */
    }
  
    .FourthContent p {
      font-size: 0.9rem; /* Adjust font size for phones */
      padding: 0 0.5rem; /* Adjust padding */
    }
  }
  
  /* Phones (portrait mode) */
  @media (max-width: 480px) {
    .FourthContent {
      padding: 0.75rem; /* Adjust padding */
    }
    
    .FourthContent h1 {
      font-size: 1.25rem; /* Adjust font size for very small screens */
    }
  
    .FourthContent p {
      font-size: 0.85rem; /* Adjust font size for very small screens */
      padding: 0 0.25rem; /* Adjust padding */
    }
  }
  