h2{
    font-size:xx-large;
}

.details {
    padding: 20px; /* Add padding around the content */
    background-color: #f4f4f9; /* Light grey background for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    max-width: 800px; /* Limit the width for better readability */
    margin: 20px auto; /* Center the div horizontally with margin */
    line-height: 1.6; /* Line height for better readability */
  }
  
  .details p {
    font-size: 1.1rem; /* Font size for readability */
    color: #333; /* Dark grey text color for contrast */
    margin: 0; /* Remove default margin */
  }
  
  .details p::first-line {
    font-weight: bold; /* Make the first line bold */
    color: #007bff; /* Change color of the first line */
  }
  




  .form-content {
    display: flex;
    justify-content: space-between; /* Align items horizontally */
    gap: 20px; /* Space between the form and contact info */
    padding: 20px;
  }
  
  .styled-form {
    flex: 1; /* Allow form to take available space */
    max-width: 500px; /* Limit form width */
    padding: 20px;
    background: #fff; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .form-group {
    margin-bottom: 15px; /* Space between form fields */
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Dark grey for better readability */
  }
  
  .form-group input {
    width: 100%; /* Full width input */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px; /* Rounded corners */
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff; /* Primary button color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth color transition */
  }
  
  .btn:hover {
    background-color: #0056b3; /* Darker color on hover */
  }
  
  .contact-info {
    flex: 1; /* Allow contact info to take available space */
    max-width: 500px; /* Limit contact info width */
    padding: 20px;
    background: #f4f4f9; /* Light background for contact info */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .contact-info h1 {
    margin-top: 0; /* Remove top margin */
    color: #333; /* Dark grey text color */
  }
  
  .contact-info p {
    font-size: 16px; /* Set font size */
    color: #555; /* Slightly lighter grey text color */
    margin-bottom: 10px; /* Space between paragraphs */
  }
  