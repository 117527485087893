/* CallButton.css */
.call-button {
    position: fixed;
    bottom: 20px; /* Distance from the bottom */
    right: 20px; /* Distance from the right */
    background-color: #f4476b; /* Background color */
    color: white; /* Icon color */
    border-radius: 50%; /* Round button */
    width: 60px; /* Width of the button */
    height: 60px; /* Height of the button */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for effect */
    font-size: 1.5rem; /* Icon size */
    text-decoration: none; /* Remove underline */
    z-index: 9999; /* Ensure it’s above other content */
}

.call-button:hover {
    background-color:green; /* Darker background on hover */
}
