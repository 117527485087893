/* PreNav Styling */
.PreNav {
  display: grid;
  text-align: center;
  position: static;
  top: 0;
  width: 100%;
  background-color: #000;
  z-index: 1000;
  padding: 0.2rem 0; /* Adjusted padding for better touch targets on all screens */
}

/* Base Styles */
body {
  background-color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 100vh;
  padding: 0 1rem;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Heading Styles */
h2 {
  color: hsl(0, 0%, 90%); /* Lighter color for better contrast on dark background */
  font-size: 1.8rem; /* Default font size for larger screens */
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 0.4rem; /* Default letter-spacing for larger screens */
  cursor: pointer;
  transition: all 0.5s ease-out;
  margin: 0 auto;
  padding: 0.75rem; /* Slightly reduced padding for better touch targets */
  word-wrap: break-word;
  box-sizing: border-box;
  max-width: 100%;
}

/* Hover Styles for Heading */
h2:hover {
  color: #fff;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  h2 {
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
  }
}

@media (max-width: 768px) {
  .PreNav {
    padding: 0.15rem 0;
  }

  h2 {
    font-size: 1.25rem; /* Smaller font size for medium screens */
    letter-spacing: 0.25rem; /* Reduced letter-spacing for medium screens */
    padding: 0.5rem; /* Adjusted padding for medium screens */
    line-height: 1.2; /* Adjusted line-height for readability */
  }
}

@media (max-width: 480px) {
  .PreNav {
    padding: 0.1rem 0;
  }

  h2 {
    font-size: 1rem; /* Smaller font size for small screens */
    letter-spacing: 0.2rem; /* Reduced letter-spacing for small screens */
    padding: 0.4rem; /* Adjusted padding for small screens */
    line-height: 1.1; /* Adjusted line-height for readability */
  }
}

@media (max-width: 320px) {
  h2 {
    font-size: 0.85rem; /* Further reduced font size for very small screens */
    letter-spacing: 0.15rem; /* Further reduced letter-spacing */
    padding: 0.3rem; /* Adjusted padding for very small screens */
    line-height: 1.1;
  }
}
