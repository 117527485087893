/* Base Footer Styling */
.Footer {
    color: red;
    background-color: #212121;
    padding: 40px 0;
    font-family: 'Arial', sans-serif;
    position: relative; /* To position floating button if needed */
}

/* Paragraph Styling */
.Footer p {
    color: red;
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 20px;
}

/* Container Styling */
.Footer .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem; /* Ensures some padding on smaller screens */
}

/* Heading Styling */
.Footer h6, .Footer h4 {
    color: #ffffff;
    margin-bottom: 20px;
    font-weight: bold;
}

.Footer h6 {
    font-size: 1.1rem;
}

.Footer h4 {
    font-size: 1.5rem;
}

/* Link Styling */
.Footer a {
    color: #d3d3d3;
    text-decoration: none;
    transition: color 0.3s ease;
}

.Footer a:hover {
    color: #ffffff;
}

/* Floating Button Styling */
.Footer .btn-floating {
    color: #ffffff;
    background: #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    font-size: 18px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.Footer .btn-floating:hover {
    background: #555;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

/* Flexbox Row Styling */
.Footer .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; /* Added gap between columns */
}

/* Column Styling */
.Footer .col-md-3,
.Footer .col-md-2,
.Footer .col-md-4 {
    margin-bottom: 20px;
    flex: 1 1 100%; /* Default to full width */
}

/* Text Alignment */
.Footer .text-center {
    text-align: center;
}

.Footer .text-md-start {
    text-align: left;
}

/* Padding and Margin Adjustments */
.Footer .p-4 {
    padding: 1rem;
}

.Footer .text-white {
    color: #ffffff;
}

.Footer .mt-3 {
    margin-top: 1rem;
}

.Footer .ml-lg-0 {
    margin-left: 0;
}

/* Responsive Styles */
@media (min-width: 992px) {
    /* Larger screens */
    .Footer .col-md-3,
    .Footer .col-md-2,
    .Footer .col-md-4 {
        flex: 1 1 30%; /* Three columns */
        max-width: 30%;
    }
}

@media (max-width: 992px) {
    /* Medium screens */
    .Footer {
        padding: 30px 0;
    }

    .Footer .col-md-3,
    .Footer .col-md-2,
    .Footer .col-md-4 {
        flex: 1 1 45%; /* Two columns */
        max-width: 45%;
    }

    .Footer .text-md-start {
        text-align: center; /* Center text on smaller screens */
    }
}

@media (max-width: 768px) {
    /* Small screens */
    .Footer {
        padding: 20px 0;
    }

    .Footer .col-md-3,
    .Footer .col-md-2,
    .Footer .col-md-4 {
        flex: 1 1 100%; /* Full width */
        max-width: 100%;
    }

    .Footer .btn-floating {
        width: 35px;
        height: 35px;
        line-height: 33px;
        font-size: 16px;
        bottom: 15px;
        right: 15px;
    }
}

@media (max-width: 576px) {
    /* Very small screens */
    .Footer {
        padding: 15px 0;
    }

    .Footer h4 {
        font-size: 1.2rem;
    }

    .Footer h6 {
        font-size: 1rem;
    }

    .Footer p {
        font-size: 0.8rem;
    }
}
