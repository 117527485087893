/* Base Styles */
.register-photo {
  background: #333; /* Dark background for contrast */
  padding: 80px 0;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.register-photo .image-holder {
  display: table-cell;
  width: 50%; /* Adjusted to ensure it takes half of the container */
  background-size: cover;
  background-position: center;
  background-image: url('https://c0.wallpaperflare.com/preview/285/1009/176/concery-wallpaper-festival-party.jpg');
  color: white;
  font-size: 2rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  padding: 60px 30px; /* Added padding for better spacing */
  border-radius: 0 10px 10px 0; /* Rounded corners on the right */
  position: relative; /* To allow positioning of button */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.register-photo .form-container {
  display: table;
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Deeper shadow for more emphasis */
  border-radius: 10px; /* Rounded corners for the form container */
  overflow: hidden; /* Ensures rounded corners are visible */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.register-photo form {
  display: table-cell;
  width: 50%; /* Adjusted to ensure it takes half of the container */
  background-color: #ffffff;
  padding: 40px 60px;
  color: #505e6c;
  border-radius: 10px; /* Rounded corners for the form */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.register-photo form h2 {
  font-size: 28px; /* Increased font size for emphasis */
  line-height: 1.5;
  margin-bottom: 30px;
  color: #333; /* Darker color for better readability */
}

.register-photo form .form-control {
  background: #f7f9fc;
  border: 1px solid #dfe7f1;
  border-radius: 5px; /* Rounded corners for inputs */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for inputs */
  outline: none;
  color: #333; /* Darker text for better readability */
  text-indent: 10px; /* Adjusted text indent for better padding */
  height: 45px; /* Slightly taller inputs */
  margin-bottom: 20px; /* Space between inputs */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.register-photo form .form-check {
  font-size: 14px; /* Slightly larger font for checkboxes */
  line-height: 22px;
}

.register-photo form .btn-primary {
  background: #f4476b;
  border: none;
  border-radius: 5px; /* Rounded corners for the button */
  padding: 12px 20px; /* Increased padding for better click area */
  font-size: 16px; /* Larger font for readability */
  color: #fff; /* White text color */
  text-shadow: 0 1px 2px rgba(0,0,0,0.2); /* Subtle text shadow for emphasis */
  transition: background 0.3s, transform 0.2s;
}

.register-photo form .btn-primary:hover, .register-photo form .btn-primary:active {
  background: #eb3b60;
  transform: scale(1.05); /* Slightly larger button on hover */
}

.register-photo form .already {
  display: block;
  text-align: center;
  font-size: 14px; /* Slightly larger font for readability */
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
  margin-top: 20px; /* Added space above the link */
}

/* Custom Button Styles */
.btn-outline-light {
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px; /* Rounded corners for button */
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.btn-outline-light:hover {
  background-color: #fff;
  color: #333;
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .register-photo .image-holder,
  .register-photo form {
      width: 100%; /* Full width on medium screens */
      display: block;
  }

  .register-photo .image-holder {
      border-radius: 0; /* Remove rounded corners on smaller screens */
      padding: 40px 20px; /* Adjust padding */
  }

  .register-photo form {
      padding: 20px 30px; /* Adjust padding */
  }
}

@media (max-width: 768px) {
  .register-photo {
      padding: 60px 0; /* Adjust padding for smaller screens */
  }

  .register-photo .image-holder,
  .register-photo form {
      width: 100%; /* Full width on smaller screens */
      display: block;
  }

  .register-photo .image-holder {
      border-radius: 0; /* Remove rounded corners on smaller screens */
      padding: 30px 10px; /* Adjust padding */
  }

  .register-photo form {
      padding: 15px 20px; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .register-photo {
      padding: 40px 0; /* Further adjust padding for very small screens */
  }

  .register-photo .image-holder {
      padding: 20px; /* Adjust padding */
      font-size: 1.5rem; /* Reduce font size for very small screens */
  }

  .register-photo form {
      padding: 10px 15px; /* Adjust padding */
  }

  .register-photo form h2 {
      font-size: 24px; /* Reduce font size for very small screens */
  }

  .register-photo form .form-control {
      height: 40px; /* Adjust height for smaller screens */
  }

  .register-photo form .btn-primary {
      padding: 10px 15px; /* Adjust padding */
      font-size: 14px; /* Reduce font size */
  }

  .btn-outline-light {
      padding: 8px 16px; /* Adjust padding */
      font-size: 14px; /* Reduce font size */
  }
}

/* Custom Button Styles */
.custom-h6 {
  display: inline-block;
  background-color: #fff; /* White background for contrast */
  color: #333; /* Dark text color for readability */
  border: 2px solid #fff; /* White border */
  padding: 15px 30px; /* Increased padding for a larger button */
  font-size: 1.5rem; /* Larger font size */
  font-weight: bold; /* Bold text for emphasis */
  text-transform: uppercase; /* Uppercase text for prominence */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline from link */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  transition: background-color 0.3s, color 0.3s, transform 0.3s; /* Smooth transitions */
  margin: 0 auto; /* Center alignment */
  display: block; /* Block display to allow margin auto to work */
  width: fit-content; /* Adjusts width based on content */
  text-align: center; /* Center text */
}

.custom-h6:hover {
  background-color: #333; /* Dark background on hover */
  color: #fff; /* White text on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.custom-h6:focus {
  outline: none; /* Remove default outline on focus */
}
