/* Base Navbar Styles */
.Navbar {
  padding: 10px 0;
  background-color: black;
  text-align: justify;
  position: sticky; /* Sticks the Navbar at the top */
  top: 0; /* Aligns it to the top */
  width: 100%; /* Ensures it spans the entire width of the viewport */
  z-index: 1000; /* Ensures it stays on top of other content */
}

.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item {
  margin: 0 15px;
}

.nav-item a {
  color: white;
  text-decoration: none;
  font-size: 20px; /* Increased font size */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  transition: color 0.3s;
}

.nav-item a:hover {
  color: rgb(244, 0, 0); /* Optional: Change color on hover */
}

.nav-item a.active {
  font-weight: bold;
}

/* Hamburger menu styles */
.navbar-toggler {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav {
    display: none;
    flex-direction: column;
  }

  .nav.open {
    display: flex;
  }

  .nav-item {
    margin: 10px 0;
  }

  .navbar-toggler {
    display: block;
    margin-left: auto;
  }
}
