/* H1 Styles */
h1 {
  color: #fff;
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 2.5rem; /* Default font size */
  margin: 1rem 5%; /* Add margin on left and right sides */
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

h1:hover {
  transform: scale(1.05);
}

/* Paragraph Styles */
p {
  color: #fff;
  text-align: center;
  justify-content: center;
  font-size: 1.25rem; /* Default font size */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 1rem 5%; /* Add margin on left and right sides */
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

p:hover {
  transform: translateY(-5px);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  h1 {
      font-size: 1.75rem; /* Smaller font size for small screens */
      margin: 1rem 5%; /* Ensure margin on small screens */
  }

  p {
      font-size: 1rem; /* Smaller font size for small screens */
      margin: 1rem 5%; /* Ensure margin on small screens */
      padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  h1 {
      font-size: 1.5rem; /* Even smaller font size for very small screens */
      margin: 0.5rem 5%; /* Ensure margin on very small screens */
  }

  p {
      font-size: 0.9rem; /* Even smaller font size for very small screens */
      margin: 0.5rem 5%; /* Ensure margin on very small screens */
      padding: 0.5rem;
  }
}

@media (max-width: 320px) {
  h1 {
      font-size: 1.25rem; /* Smallest font size for very small screens */
      margin: 0.25rem 5%; /* Ensure margin on very small screens */
  }

  p {
      font-size: 0.8rem; /* Smallest font size for very small screens */
      margin: 0.25rem 5%; /* Ensure margin on very small screens */
      padding: 0.5rem;
  }
}
