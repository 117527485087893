/* Container Styles */
header {
  position: relative;
  overflow: hidden; /* Ensure no overflow from absolute positioning */
}

/* Background Image and Mask Styles */
.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px; /* Default height for larger screens */
  width: 100%;
  position: relative;
  transition: height 0.3s ease; /* Smooth transition for responsive changes */
}

/* Mask Styles */
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Darker overlay for better contrast */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
}

/* Text Styles */
.text-white {
  color: #fff;
  text-align: center;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for better readability */
}

.custom-h1 {
  font-size: 2.5rem; /* Large font size for headers */
  font-weight: bold;
  margin-bottom: 1rem;
  transition: font-size 0.3s ease; /* Smooth transition for responsive changes */
}

.custom-h4 {
  font-size: 1.2rem; 
  margin-bottom: 1.5rem;
  transition: font-size 0.3s ease; /* Smooth transition for responsive changes */
}

.custom-h6 {
  font-size: 1rem;
  transition: font-size 0.3s ease; /* Smooth transition for responsive changes */
}

/* Button Styles */
.btn {
  border: 2px solid #fff;
  color: #fff;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
  background: transparent;
}

.btn-outline-light:hover {
  background-color: #fff;
  color: #000;
  transform: scale(1.1); /* Slightly enlarge the button on hover for emphasis */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .bg-image {
    height: 350px;
  }

  .custom-h1 {
    font-size: 2rem;
  }

  .custom-h4 {
    font-size: 1rem;
  }

  .custom-h6 {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .bg-image {
    height: 300px;
  }

  .custom-h1 {
    font-size: 1.75rem;
  }

  .custom-h4 {
    font-size: 0.9rem;
  }

  .custom-h6 {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .bg-image {
    height: 250px;
  }

  .custom-h1 {
    font-size: 1.5rem;
  }

  .custom-h4 {
    font-size: 0.8rem;
  }

  .custom-h6 {
    font-size: 0.75rem;
  }

  .btn {
    padding: 0.4rem 0.8rem; /* Adjust padding for smaller buttons */
  }
}
