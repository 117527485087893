/* General Styles */
h1 {
    color: white;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 2.5rem; /* Larger font size for better emphasis */
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Adds a subtle shadow for better readability */
  }
  
  /* Artist Section */
  .Artist {
    text-align: center;
    padding: 40px 20px; /* Increased padding for more spacing */
    background: rgba(0, 0, 0, 0.7); /* Slightly darker background for contrast */
    border-radius: 10px; /* Rounded corners for a softer look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow around the container */
    margin-bottom: 100px; /* Adjusted margin for spacing */
  }
  
  .Artist h1 {
    margin-bottom: 30px; /* Increased margin for better spacing */
  }
  
  /* Row Styles */
  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px; /* Added gap between columns */
  }
  
  /* Column Styles */
  .col-lg-4, .col-md-12 {
    flex: 0 0 30%; /* Adjust the percentage as needed */
    max-width: 30%;
    margin: 0; /* Removed margin to use gap in row */
    padding: 10px; /* Added padding inside columns */
  }
  
  /* Image Styles */
  img {
    width: 100%;
    max-width: 100%; /* Set max-width to 100% to ensure responsiveness */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 10px; /* Rounded corners for images */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  }
  
  /* Image Hover Effect */
  img:hover {
    transform: scale(1.1); /* Slightly enlarge the image on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow effect on hover */
  }
  