/* Base styles */
.a {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden; /* Prevent content overflow */
}

.a h1 {
  position: relative;
  font-size: 2.5rem;
  color: #fff;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background for better readability */
  border-radius: 10px;
  max-width: 100%; /* Ensure text does not overflow */
  word-wrap: break-word; /* Ensure text wraps within the container */
}

.b {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
}

.b > div {
  flex: 1;
  min-width: 300px;
  background: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent content overflow */
}

.b img {
  max-width: 100%;
  border-radius: 10px;
}

.b h1,
.b h2 {
  color: #333;
}

.b p {
  color: #666;
  line-height: 1.6;
}

.dis {
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: red;
  font-size: 0.9rem;
  line-height: 1.4;
  overflow: hidden; /* Prevent content overflow */
}

.form {
  text-align: left;
}

/* Responsive Layout */
@media (max-width: 1024px) {
  .a h1 {
      font-size: 2rem;
      padding: 8px 16px;
  }

  .b > div {
      min-width: 280px;
  }
}

@media (max-width: 768px) {
  .b {
      flex-direction: column;
  }

  .a h1 {
      font-size: 1.8rem;
      padding: 8px 16px;
  }

  .dis {
      font-size: 0.8rem;
  }

  /* Reduce side margins */
  .a, .b, .dis, .form-container, .last {
      margin: 10px;
  }
}

@media (max-width: 480px) {
  .a {
      margin: 5px 0;
  }

  .a h1 {
      font-size: 1.5rem;
      padding: 6px 12px;
  }

  .b > div {
      min-width: 100%;
  }

  .dis {
      font-size: 0.75rem;
      padding: 10px;
  }

  .form-container {
      padding: 15px;
  }

  .styled-form {
      padding: 15px;
  }

  .contact-info {
      margin-top: 20px;
      margin-left: 0;
      text-align: center;
  }

  /* Further reduce side margins for small devices */
  .a, .b, .dis, .form-container, .last {
      margin: 5px;
      padding: 10px;
  }
}

/* Form container */
.styled-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 20px auto;
}

/* Container for the form and contact info */
.form-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden; /* Prevent content overflow */
}

/* Flexbox layout */
.form-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
}

/* Contact info styles */
.contact-info {
  flex: 1;
  margin-left: 20px;
  text-align: left;
}

/* Section styling */
.last {
  padding: 20px;
  background-color: #f9f9f9; /* Light background color for better readability */
  border: 1px solid #ddd; /* Light border to define the section */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted effect */
  max-width: 800px; /* Limit the width of the section */
  margin: 20px auto; /* Center the section and add margin */
  font-family: Arial, sans-serif; /* Clean font for readability */
  overflow: hidden; /* Prevent content overflow */
}

.last h2 {
  margin-top: 0; /* Remove top margin */
  font-size: 24px; /* Increase font size for heading */
  color: #333; /* Dark text color for better readability */
  border-bottom: 2px solid #007bff; /* Bottom border for emphasis */
  padding-bottom: 10px; /* Space below heading */
}

.last p {
  line-height: 1.6; /* Increase line height for readability */
  font-size: 16px; /* Set a comfortable font size */
  color: #555; /* Slightly lighter text color */
  margin-bottom: 20px; /* Space between paragraphs */
}

.last a {
  color: #007bff; /* Primary color for links */
  text-decoration: none; /* Remove underline from links */
}

.last a:hover {
  text-decoration: underline; /* Underline link on hover */
}

.last a {
  text-decoration: none;
}
